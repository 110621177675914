@import '../../styles/variables';

.footer {
  background-color: $black;
  text-align: center;
  padding: 12px 0; }

.footer-message {
  color: $gray;
  font-size: 10px;
  line-height: 12px; }
