@import '../../styles/variables';

.case-studies {
  display: flex;
  overflow-x: hidden;

  &.container {
    margin-bottom: 80px;

    @media screen and (min-width: 768px) {
      margin-bottom: 160px; } } }


.case-studies-subtitles {
  display: flex;
  flex-direction: column;
  width: min-content;
  margin-right: 39px;

  @media screen and (min-width: 576px) {
    margin-right: 95px; }

  @media screen and (min-width: 1440px) {
    margin-right: 126px; } }


.case-studies-quantity {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-top: 6px;
  margin-bottom: 184px;

  @media screen and (min-width: 576px) {
    margin-bottom: 142px; }

  @media screen and (min-width: 768px) {
    margin-bottom: 173px; } }


.case-studies-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin: 0 0 32px;

  @media screen and (min-width: 576px) {
    font-size: 32px;
    line-height: 40px; }

  @media screen and (min-width: 768px) {
    font-size: 38px;
    line-height: 47px; } }


//Slider.js
.slider {
  height: 318px;
  width: 637px;

  .swiper-button-next,
  .swiper-button-prev {
    color: $gray; }

  @media screen and (min-width: 576px) {
    width: 761px;
    height: unset; }

  @media screen and (min-width: 768px) {
    width: 1000px; }

  @media screen and (min-width: 1240px) {
    width: 1500px; }

  @media screen and (min-width: 1440px) {
    width: 1506px; } }


//SwiperSlideContent.js
.slider-slide-content {
  display: flex;
  flex-direction: column; }

.slider-slide-image-container {
  position: relative;
  cursor: pointer;
  margin-bottom: 8px;

  @media screen and (min-width: 576px) {
    margin-bottom: 10px; }

  @media screen and (min-width: 768px) {
    margin-bottom: 12px; }


  .slider-slide-overlay {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    opacity: 0;
    z-index: 2;
    transition: opacity $transition;

    span {
      margin-left: 11px;
      font-weight: 600;

      @media screen and (max-width: 575px) {
        margin-left: 8px;
        font-size: 14px; } } }



  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: $gradient;
    z-index: 1;
    opacity: 0;
    transition: opacity $transition; }

  &:hover::before {
    opacity: 1; }

  &:hover {
    .slider-slide-overlay {
      opacity: 1; } } }


.slider-slide-image {
  display: block;
  height: auto;
  width: 207px;

  @media screen and (min-width: 576px) {
    width: 368px; }

  @media screen and (min-width: 768px) {
    width: 468px; } }


.slider-slide-subtitle {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: $gray;
  margin-bottom: 2px; }

.slider-slide-title {
  font-weight: 600; }
