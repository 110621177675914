@import '../../styles/variables';

.contact {
  &.container {
    margin-bottom: 80px;

    @media screen and (min-width: 768px) {
      margin-bottom: 160px; } } }


.contact-titles {
  display: flex;
  margin-bottom: 40px; }

.contact-sub-title {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  width: 47px;
  margin-right: 29px;

  @media screen and (min-width: 576px) {
    font-size: 12px;
    line-height: 15px;
    width: 105px; }

  @media screen and (min-width: 1440px) {
    margin-right: 58px; } }


.contact-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin: 0;

  @media screen and (min-width: 576px) {
    font-size: 32px;
    line-height: 40px; }

  @media screen and (min-width: 768px) {
    font-size: 38px;
    line-height: 47px; } }


.form {
  display: flex;
  margin-left: 76px;
  flex-direction: column;

  @media screen and (min-width: 576px) {
    flex-direction: row;
    margin-left: 131px; }

  @media screen and (min-width: 768px) {
    margin-left: 135px; }

  @media screen and (min-width: 1440px) {
    margin-left: 163px; }


  .form-inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    @media screen and (min-width: 576px) {
      margin-bottom: 0;
      margin-right: 16px;
      width: 469px; }

    @media screen and (min-width: 768px) {
      margin-right: 32px; } }


  .form-input {
    &:not(:last-child) {
      margin-bottom: 20px; }

    .MuiInputBase-root:before {
      border-bottom: 1px solid $gray; }

    &:hover {
      .MuiInputBase-root:before {
        border-bottom: 1px solid $gray; } }


    &.error {
      .MuiInputBase-root:before {
        border-bottom: 1px solid $red; } }

    .MuiInputBase-root:after {
      border-bottom: 2px solid $white; }

    label {
      font-size: 14px;
      line-height: 17px;
      top: 14px;
      color: $gray;

      &.MuiInputLabel-shrink {
        top: 6px; } }

    input {
      padding: 15px 0 15px;
      color: $white;
      font-size: 14px;
      line-height: 17px;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px $gray inset !important; }

      &:-webkit-autofill {
        -webkit-padding-start: 10px;
        -webkit-text-fill-color: $dark !important; } }


    .MuiFormHelperText-root.Mui-error {
      color: $red; } }


  .form-button {
    display: flex;
    align-items: center;
    align-self: flex-end;
    cursor: pointer;
    border-radius: 6px;
    border: transparent;
    color: $white;
    background-color: transparent;
    transition: padding-right $transition;

    @media screen and (min-width: 576px) {
      margin-bottom: 43px; }

    &:hover {
      padding-right: 0;

      span {
        margin-right: 10px; } }


    span {
      padding: 8px;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      margin-right: 4px;
      transition: margin-right $transition; } } }


.email-status-banner {
  margin-left: 75px;
  width: 303px;
  text-align: center;

  @media screen and (min-width: 576px) {
    margin-left: 131px; }

  @media screen and (min-width: 1440px) {
    margin-left: 163px; }


  h3 {
    margin: 18px 0 12px;
    font-size: 18px; }

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%; }

  button {
    padding: 8px 8px 8px 16px;
    margin: 12px auto 0;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: transparent;
    color: $white;
    cursor: pointer;

    .refresh-icon {
      transition: transform $transition;

      path {
        transition: fill $transition; } }


    &:hover {
      .refresh-icon {
        transform: rotate(360deg);

        path {
          fill: $green; } }

      span {
        color: $green; } }

    span {
      margin-right: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      transition: color $transition; } } }


.form-inputs-recaptcha {
  font-size: 10px;
  line-height: 17px;
  top: 14px;
  color: $gray;

  a {
    color: $gray;
    transition: color $transition; }

  a:hover {
    color: $green; } }
