@import '../../styles/variables';

.team {
  &.container {
    margin-bottom: 80px;

    @media screen and (min-width: 768px) {
      margin-bottom: 160px; } } }


.team-titles {
  margin-bottom: 32px;
  display: flex;
  align-items: center; }

.team-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin: 0;

  @media screen and (min-width: 576px) {
    font-size: 32px;
    line-height: 40px; }

  @media screen and (min-width: 768px) {
    font-size: 38px;
    line-height: 47px; } }


.team-subtitle {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  margin-right: 29px;
  width: 47px;

  @media screen and (min-width: 576px) {
    margin-right: 61px;
    font-size: 12px;
    line-height: 15px;
    width: 70px; }

  @media screen and (min-width: 1440px) {
    margin-right: 93px; } }


.team-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 75px;

  @media screen and (min-width: 576px) {
    max-width: 424px;
    flex-direction: row;
    margin-left: 131px; }

  @media screen and (min-width: 768px) {
    max-width: 573px; }

  @media screen and (min-width: 1024px) {
    max-width: 730px; }

  @media screen and (min-width: 1440px) {
    margin-left: 163px; }

  .card0 {
    background: url("../images/ivan.jpg") center center no-repeat; }

  .card0:hover {
    background: url("../images/ivan.jpg") left center no-repeat; }

  .card1 {
    background: url("../images/ievgenii.jpg") center center no-repeat; }

  .card1:hover {
    background: url("../images/ievgenii.jpg") left center no-repeat; }

  .card2 {
    background: url("../images/artem.jpg") center center no-repeat; }

  .card2:hover {
    background: url("../images/artem.jpg") left center no-repeat; }

  .card {
    position: relative;
    background-size: 131px;
    box-sizing: border-box;
    padding: 16px 8px;
    height: 174px;
    width: 131px;
    transition: background 0.8s;
    overflow: hidden;
    box-shadow: 0 35px 31.5px -30px #000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: $gradient;
      z-index: 1;
      opacity: 0;
      transition: opacity $transition; }

    &:hover::before {
      opacity: 1; }

    @media screen and (min-width: 768px) {
      background-size: 179px;
      height: 238px;
      width: 179px;
      box-shadow: 0 42px 37.5px -35px #000; }

    @media screen and (min-width: 1024px) {
      background-size: 222px;
      height: 295px;
      width: 222px;
      box-shadow: 0 49.5px 44.25px -41.5px #000; }

    @media screen and (max-width: 575px) {
      &:not(:last-child) {
        margin-bottom: 16px; } }


    &:hover {
      background-size: 260px;

      @media screen and (min-width: 768px) {
        background-size: 350px; }

      @media screen and (min-width: 1024px) {
        background-size: 435px; }

      h2,
      span {
        opacity: 1;
        z-index: 2; } } }


  h2 {
    color: $white;
    margin: 0 0 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    opacity: 0;
    transition: opacity $cardTransition; }

  span {
    font-size: 12px;
    line-height: 15px;
    color: $green;
    opacity: 0;
    transition: opacity $cardTransition; } }
