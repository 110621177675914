@import '../../styles/variables';

.capabilities {
  &.container {
    margin-bottom: 80px;

    @media screen and (min-width: 768px) {
      margin-bottom: 160px; } } }


.capabilities-titles {
  margin-bottom: 32px;
  display: flex;
  align-items: center; }

.capabilities-asterisk {
  margin-right: 55px;

  @media screen and (min-width: 576px) {
    margin-right: 111px; }

  @media screen and (min-width: 1440px) {
    margin-right: 143px; } }


.capabilities-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin: 0;

  @media screen and (min-width: 576px) {
    font-size: 32px;
    line-height: 40px; }

  @media screen and (min-width: 768px) {
    font-size: 38px;
    line-height: 47px; } }
