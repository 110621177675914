$white:  #FFFFFF;
$gray: #82858C;
$dark: #191C21;
$red: #E85C4A;
$black: #000000;
$green: #BAF689;


// Basic measurements
$transition: 0.2s ease-in-out;
$cardTransition: 1s ease-in-out;
$gradient: linear-gradient(0deg, rgba(25, 28, 33, 0.7), rgba(25, 28, 33, 0.7));
