@import '../../styles/variables';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-logo {
    @media screen and (min-width: 768px) {
      margin-right: 32px; }

    @media screen and (min-width: 1440px) {
      margin-right: 60px; } } }

.header-nav {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  &.side-bar {
    display: flex;
    flex-direction: column;
    margin-left: 65px;
    margin-bottom: auto;

    .header-nav-item {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px; }

    .header-nav-link {
      margin-right: 0;
      padding: 8px 16px;
      width: fit-content;

      &:not(:last-child) {
        margin-bottom: 9px; } } } }


.header-nav-link {
  margin-right: 16px; }

.header-nav-item {
  display: inline-block;
  color: $white;
  transition: color $transition;

  &:hover {
    color: $green; } }


.header-links {
  margin-left: auto;

  @media screen and (max-width: 767px) {
    margin-right: 16px; }

  &.side-bar {
    margin: 0 auto; }

  .header-links-icon {
    vertical-align: bottom;

    path {
      transition: fill $transition; }

    &:hover {
      path {
        fill: $green; } } } }


.header-links-item:not(:last-child) {
  margin-right: 8px; }

.header-burger-button {
  background-color: transparent;
  border: none;
  cursor: pointer; }


//SideBar.js
.MuiDrawer-root {
  .MuiDrawer-paper {
    box-sizing: border-box;
    background-color: $dark;
    padding: 32px 10px 134px;
    width: 283px;
    height: 100vh; } }


.sidebar-close-button {
  background-color: transparent;
  border: none;
  width: fit-content;
  margin-left: auto;
  margin-bottom: 55px;
  cursor: pointer;

  svg {
    stroke: $white;
    transition: stroke $transition; }

  &:hover {
    svg {
      stroke: $green; } } }

.side-bar-contact-us-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  margin-top: 32px;
  margin-bottom: 32px;
  color: $dark;
  text-decoration: none;
  background-color: white;
  border: 2px solid transparent;
  cursor: pointer;
  transition: background-color $transition, border-color $transition;

  path {
    transition: fill $transition; }

  &:hover {
    background-color: transparent;
    border-color: $white;

    .side-bar-contact-us-button-title {
      color: $white; }

    path {
      fill: $white; } } }


.side-bar-contact-us-button-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-left: 8px;
  transition: color $transition; }
